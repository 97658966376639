<style scoped>
h3 {
  color: white;
}

h4 {
  color: white;
}

p {
  color: white;
}

.message {
  color: #f44336;
}

.content {
  height: 100vh;
  padding: 0px;
}

.left-side {
  /* background: #2196F3; */
}

.left-side img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25%;
  display: block;
}

.right-side {
  /* padding:320px; */
  background: #023e73;
}

.right-side .right-side-content-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 18%;
}

.right-side .right-side-content {
  /* padding:50px; */
  /* margin-left: 35%; */
  /* margin-right: 35%; */
  width: 500px;
  color: white;
  margin: auto;
  /* width: 50%; */
  /* border: 3px solid green; */
  /* padding: 10px; */
}

.accept {
  background: #66a626;
  margin: 10px;
  border-radius: 7px;
  width: 100px;
  color: white;
}

.accept:hover {
  background: #2d7100;
  color: white;
}

input {
  border-radius: 7px;
  background: #f3f6f9;
}

input:focus {
  background: #cfd8dc;
}

span {
  /* border-radius: 7px; */
}

@media screen and (max-width: 1023px) {
  .left-side {
    /* height: 100px; */
  }

  .left-side img {
    margin-top: 55px;
    margin-bottom: 10px;
    width: 200px;
    /* height: 100%;; */
  }

  .right-side .right-side-content-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 18%;
  }

  .right-side .right-side-content {
    width: 300px;
    color: white;
    margin: auto;
  }

  .right-side {
    /* padding:320px; */
    background: #023e73;
  }
}
</style>
<template>
  <div>
    <div
      class="login login-1 content row"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="col-sm-12 col-lg-6 left-side" style="background: white">
        <div class="">
          <a
            href="https://penilaiankinerja.bkpm.go.id"
            class="text-center mb-10"
          >
            <img
              style="text-align: center; width: 90%"
              src="/media/logos/logobkpmhirilasi.png"
              alt=""
            />
          </a>
          <br />
          <div
            class=""
            style="text-align: center; font-size: 21px; margin: 0 58px"
          >
            Penilaian Kinerja Pelayanan Terpadu Satu Pintu dan Kinerja
            Percepatan Pelaksanaan Berusaha Pemerintah Daerah Serta Kinerja
            Percepatan Pelaksanaan Berusaha Kementerian Negara/Lembaga
          </div>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div class="col-sm-12 col-lg-6 right-side">
        <div class="">
          <!--begin::Signin-->
          <div class="login-form login-signin right-side-content-container">
            <form
              class="form right-side-content"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit="login"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <span class="text-muted font-weight-bold text-dark font-size-h7"
                  >Silakan masukkan username dan password</span
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-white"
                  >Username / NIP
                  <span
                    ><i
                      v-b-popover.hover.top="
                        'NIP hanya digunakan untuk Peserta BIMTEK!'
                      "
                      title="Informasi"
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      style="color: orange; font-size: 10px"
                    ></i></span
                ></label>
                <div
                  id="example-input-group-1"
                  label="text"
                  label-for="example-input-1"
                >
                  <!-- <input
                        class="form-control form-control-solid h-auto rounded-lg"
                        type="text"
                        name="email"
                        ref="email"
                        v-model="form.username"
                      /> -->
                  <v-text-field
                    single-line
                    solo
                    type="text"
                    name="email"
                    ref="email"
                    placeholder="Masukan Username / NIP"
                    v-model="form.username"
                  ></v-text-field>
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-white pt-5"
                    >Password</label
                  >
                  <!-- <a
                        id="kt_login_signup"
                        class="text-primary font-weight-bolder"
                        @click="showForm('signup')"
                        >Create an Account</a
                      > -->
                </div>
                <!-- <div
                      id="example-input-group-2"
                      label=""
                      label-for="example-input-2"
                    >
                      <input
                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        name="password"
                        ref="password"
                        v-model="form.password"
                        autocomplete="off"
                      />
                    </div> -->
                <div class="input-group">
                  <!-- <input class="form-control form-control-solid h-auto py-6 px-6 rounded-lg" id="login_password" type="password" name="password" autocomplete="off" /> -->
                  <!-- <input
                        class="form-control form-control-solid h-auto rounded-lg"
                        type="password"
                        name="password"
                        ref="password"
                        v-model="form.password"
                        autocomplete="off"
                        id="login_password"
                      /> -->
                  <br />
                  <v-text-field
                    id="login_password"
                    v-model="form.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    single-line
                    solo
                    clear-icon="mdi-close-circle"
                    :type="typePassword"
                    name="password"
                    ref="password"
                    placeholder="Masukkan Password"
                    @click:append="_toggleShowPassword"
                  ></v-text-field>
                  <!-- <div class="input-group-append">
    										 <button class="btn btn-secondary" @click="visibility3()" id="eyeSlash" type="button"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
    										 <button class="btn btn-secondary" @click="visibility3()" style="display: none;" id="eyeShow" type="button"><i class="fa fa-eye" aria-hidden="true"></i></button>
    										</div> -->
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <!-- <vue-recaptcha sitekey="6Lft9ZIaAAAAALeo-Xg8woXQGN2nN177ZMcx_FMA"> -->
                <vue-recaptcha
                  @verify="markRecaptchaAsVerified"
                  sitekey="6LdzldspAAAAAMGX8xic6cXDcTnOxLyS8lWfKJWX"
                ></vue-recaptcha>
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-sm px-15 py-4 my-3 font-size-lg mr-3"
                  type="submit"
                  @click="checkIfRecaptchaVerified"
                >
                  Sign In
                </button>
                <!-- </vue-recaptcha> -->
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder py-4 my-3 mr-3 font-size-lg"
                  @click="showForm('forgot')"
                >
                  Lupa Password
                </button>
                <button
                  type="button"
                  class="btn btn-success font-weight-bolder py-4 my-3 font-size-lg"
                  @click="showForm('signup')"
                >
                  Pendaftaran Surveyor
                </button>
                <!-- <strong>{{ pleaseTickRecaptchaMessage }}</strong> -->
              </div>
              <div>
                <strong>{{ pleaseTickRecaptchaMessage }}</strong>
              </div>
              <br />
              <!-- <b-button
                      :href="download"
                      class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg" @click="unduhapk"
                    >
                      <i class="socicon-android icon-md"></i>Unduh Penkin Mobile
                    </b-button> -->
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div
            class="login-form login-signup right-side-content-container"
            style="margin-top: 5%"
          >
            <form
              class="form right-side-content"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-white font-size-h4 font-size-h1-lg"
                >
                  Buat Akun
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Masukkan detail data Anda untuk membuat akun baru
                </p>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Nama <span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <input
                    type="text"
                    required
                    v-model="name"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Email<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <input type="email" v-model="email" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >No Hp<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="no_hp"
                    onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Alamat<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <textarea
                    :rows="3"
                    v-model="alamat"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Tempat/Tanggal Lahir<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-xl-6">
                      <!--begin::Input-->
                      <div class="form-group">
                        <input
                          type="text"
                          v-model="tempat_lahir"
                          class="form-control"
                        />
                      </div>
                      <!--end::Input-->
                    </div>
                    <div class="col-xl-6">
                      <!--begin::Input-->
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="date"
                          v-model="tanggal_lahir"
                          id="example-date-input"
                        />
                        <!-- <b-form-datepicker v-model="tanggal_lahir" id="example-datepicker" class="mb-2"></b-form-datepicker> -->
                      </div>
                      <!--end::Input-->
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 text-white"
                  >Pendidikan Terakhir<span style="color: red">*</span></label
                >
                <div class="col-8">
                  <b-form-select
                    :options="pendidikan"
                    v-model="pendidikan_terakhir"
                    style="width: 150px"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Bidang Ilmu<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="bidang_ilmu"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Wilayah Kerja Verval<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <b-form-select v-model="id_ptsp">
                    <option
                      v-for="(dataprovinsi, k) in provinsi"
                      :key="k"
                      :value="dataprovinsi.id"
                    >
                      {{ dataprovinsi.name }}
                    </option>
                  </b-form-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Upload File Ijazah<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <v-file-input
                    type="file"
                    ref="file"
                    accept="image/*"
                    outlined
                    dense
                    dark
                    @change="_onFileChange"
                  ></v-file-input>
                  <span>Unggah file berformat PNG, JPG dan JPEG.</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Username<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <input type="text" v-model="username" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label text-white"
                  >Password<span style="color: red">*</span></label
                >
                <div class="col-lg-8">
                  <input
                    type="password"
                    v-model="password"
                    class="form-control"
                  />
                </div>
              </div>
              <div>
                <vue-recaptcha
                  @verify="markRecaptchaAsVerified"
                  sitekey="6LdzldspAAAAAMGX8xic6cXDcTnOxLyS8lWfKJWX"
                ></vue-recaptcha>
                <strong>{{ pleaseTickRecaptchaMessage }}</strong>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <!-- <vue-recaptcha sitekey="6Lek8ZIaAAAAAA0VqyT7SMd3VHKfMykuJQKKJtDO"> -->
                <button
                  id="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                  @click="checkIfRecaptchaVerified2"
                >
                  Submit
                </button>
                <!-- </vue-recaptcha> -->
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot right-side-content-container">
            <!--begin::Form-->
            <form
              class="form right-side-content"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <!-- <h3
                      class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                    >
                      Forgotten Password ?
                    </h3> -->
                <span class="text-muted font-weight-bold text-dark font-size-h7"
                  >Lupa Password ?</span
                >
                <br />
                <span class="text-muted font-weight-bold text-dark font-size-h7"
                  >Masukkan email untuk mendapatkan password</span
                >
                <!-- <p class="text-muted font-weight-bold font-size-h4">
                      Enter your email to reset your password
                    </p> -->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-white"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <!-- <input
                        class="form-control form-control-solid h-auto rounded-lg"
                        type="text"
                        name="email"
                        ref="email"
                        v-model="form.username"
                      /> -->
                  <v-text-field
                    single-line
                    solo
                    type="text"
                    name="email"
                    ref="email"
                    v-model="email"
                  ></v-text-field>
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  @click="lupapassword"
                >
                  Kirim
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Batal
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <!-- <a href="#" class="text-primary font-weight-bolder font-size-h5"
                >Terms</a
              >
              <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
                >Plans</a
              >
              <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
                >Contact Us</a
              > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>
<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer></script>
<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.js"></script>
<!-- Minify -->
<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"></script>


<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { VueRecaptcha } from "vue-recaptcha";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import axios from "axios";
import VueLoading from "vuejs-loading-plugin";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "login-1",
  components: { VueRecaptcha },
  data() {
    return {
      download: "",
      showPassword: false,
      typePassword: "password",
      state: "signin",
      // Remove this dummy login info
      user: localStorage.getItem("id_token"),
      urlpenilaian: localStorage.getItem("baseapipenilaian"),
      url: localStorage.getItem("baseapi"),
      form: {
        email: "",
        password: "",
      },
      recaptchaVerified: false,
      pleaseTickRecaptchaMessage: "",
      pendidikan: [
        { value: null, text: "Silahkan Pilih" },
        { value: "SD/Sederajat", text: "SD/Sederajat" },
        { value: "SMP/Sederajat", text: "SMP/Sederajat" },
        { value: "SMA/Sederajat", text: "SMA/Sederajat" },
        { value: "D1", text: "D1" },
        { value: "D2", text: "D2" },
        { value: "D3", text: "D3" },
        { value: "D4", text: "D4" },
        { value: "S1", text: "S1" },
        { value: "S2", text: "S2" },
        { value: "S3", text: "S3" },
      ],
      provinsi: [],
      name: "",
      alamat: "",
      bidang_ilmu: "",
      username: "",
      ijazah: "",
      kode_wilayah: "",
      no_hp: "",
      password: "",
      pendidikan_terakhir: "",
      tanggal_lahir: "",
      tempat_lahir: "",
      email: "",
      selectedrole: "",
      id_ptsp: "",
      hasil: [],
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    this.getprovinsi();
    this.unduhapk();
    if (localStorage.getItem("id_token")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      // this.getuser(this.user);
      // localStorage.setItem('idpmppb', 'undefined')
      // localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      // localStorage.setItem('idprofil', 'undefined');
      // localStorage.setItem('idpm', 'undefined');
      localStorage.setItem("idpmppb", "undefined");
      // location.reload();
      // this.getuser(this.user);
    }

    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    // this.fv.on("core.form.valid", () => {
    //   var email = this.form.email;
    //   var password = this.form.password;

    //   // clear existing errors
    //   this.$store.dispatch(LOGOUT);

    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_login_signin_submit"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");

    //   // dummy delay
    //   setTimeout(() => {
    //     // send login request
    //     this.$store
    //       .dispatch(LOGIN, { email, password })
    //       // go to which page after successfully login
    //       .then(() => this.$router.push({ name: "dashboard" }))
    //       .catch(() => {});

    //     submitButton.classList.remove(
    //       "spinner",
    //       "spinner-light",
    //       "spinner-right"
    //     );
    //   }, 2000);
    // });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    // this.fv1.on("core.form.valid", () => {
    //   const email = this.$refs.remail.value;
    //   const password = this.$refs.rpassword.value;

    //   // clear existing errors
    //   this.$store.dispatch(LOGOUT);

    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_login_signup_submit"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");

    //   // dummy delay
    //   setTimeout(() => {
    //     // send register request
    //     this.$store
    //       .dispatch(REGISTER, {
    //         email: email,
    //         password: password
    //       })
    //       .then(() => this.$router.push({ name: "dashboard" }));

    //     submitButton.classList.remove(
    //       "spinner",
    //       "spinner-light",
    //       "spinner-right"
    //     );
    //   }, 2000);
    // });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
  methods: {
    markRecaptchaAsVerified(response) {
      this.pleaseTickRecaptchaMessage = "";
      this.recaptchaVerified = true;
    },
    checkIfRecaptchaVerified() {
      if (!this.recaptchaVerified) {
        this.pleaseTickRecaptchaMessage = "Please tick recaptcha.";
        return true; // prevent form from submitting
      }
      this.login();
    },
    checkIfRecaptchaVerified2() {
      if (!this.recaptchaVerified) {
        this.pleaseTickRecaptchaMessage = "Please tick recaptcha.";
        return true; // prevent form from submitting
      }
      this.tambahuser();
    },
    _signUp() {
      console.log(this.form);
    },
    _toggleShowPassword() {
      this.typePassword =
        this.typePassword === "password" ? "text" : "password";
      this.showPassword = !this.showPassword;
    },
    _onFileChange(e) {
      // this.data.url = URL.createObjectURL(e);
      this.$loading(true);
      let root = this;
      var formData = new FormData();
      formData.append("file", e);
      // console.log("koko ni iru");
      axios
        .post(this.url + "/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.data);
          root.ijazah = response.data.data;
          // console.log()
          this.$loading(false);
        })
        .catch((error) => {
          console.error(error);
          this.$loading(false);
        });
    },
    async login() {
      // this.$loading(true)
      const username = this.form.username;
      const password = this.form.password;
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      try {
        let response = await axios.post(this.url + "/login", {
          username,
          password,
          headers: {
            "Content-type": "application/json",
          },
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        console.log(response.data.token);
        let datauser = await axios.get(this.url + "/detail", {
          headers: {
            xth: response.data.token,
          },
        });
        // console.log('hailsnya')
        //   console.log(datauser)
        localStorage.setItem("user", JSON.stringify(datauser.data.data));
        // console.log('test1')
        this.$store
          .dispatch(LOGIN, { username, password })
          .then(() => this.$router.push({ name: "dashboard" }));
        // console.log('ini eksekusi enggga')
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        this.$bvToast.toast(error.response.data.message, {
          title: `Login Failed `,
          variant: `danger`,
          solid: true,
        });
        // console.log(error.response.data.message)
        return error;
      }
    },
    // getuser(user){
    //     axios.get(this.url+'/detail', {
    //     headers: {
    //         'xth': `${user}`
    //     }
    //     })
    //     .then((res) => {
    //       this.hasil = res.data.data
    //       var hasilnih = res.data
    //       console.log(Object.keys(this.hasil).length)
    //       console.log('hailsnya')
    //       localStorage.setItem('user', JSON.stringify(res.data.data))
    //     })
    //     .catch((error) => {
    //       this.$bvToast.toast(error.response.data.message, {
    //             title: `Login Failed `,
    //             variant: `danger`,
    //             solid: true
    //         })
    //     // console.error(error)
    //     })
    // },
    getprovinsi() {
      axios.get(this.url + "/provinsis").then((response) => {
        this.provinsi = response.data;
      });
    },
    unduhapk() {
      this.download = `https://penilaiankinerja.bkpm.go.id/apk/appPenkin.apk`;
    },
    tambahuser() {
      //console.log(this.$refs.file.text[0])
      if (
        this.name === "" ||
        this.username == "" ||
        this.email == "" ||
        this.id_ptsp == "" ||
        this.password == "" ||
        this.tanggal_lahir == "" ||
        this.pendidikan_terakhir == "" ||
        this.no_hp == "" ||
        this.alamat == "" ||
        this.bidang_ilmu == "" ||
        this.ijazah == "" ||
        this.$refs.file.text[0] == undefined
      ) {
        this.$bvToast.toast("Gagal Dikirim, pastikan semua data diisi", {
          title: `Failed `,
          variant: `danger`,
          solid: true,
        });
      } else {
        if (this.id_ptsp == 31) {
          (this.id_role = "surveyor_kl"), (this.name_role = "Surveyor K/L");
        } else {
          (this.id_role = "surveyor_pemda"),
            (this.name_role = "Surveyor Pemda");
        }
        let formData = new FormData();
        formData.append("name", this.name);
        formData.append("username", this.username);
        formData.append("email", this.email);
        formData.append("id_ptsp", this.id_ptsp);
        formData.append("password", this.password);
        formData.append("tanggal_lahir", this.tanggal_lahir);
        formData.append("tempat_lahir", this.tempat_lahir);
        formData.append("ijazah", this.ijazah);
        formData.append("id_role", this.id_role);
        formData.append("name_role", this.name_role);
        formData.append("pendidikan_terakhir", this.pendidikan_terakhir);
        formData.append("no_hp", this.no_hp);
        formData.append("alamat", this.alamat);
        formData.append("bidang_ilmu", this.bidang_ilmu);
        // tampak_depan
        axios
          .post(this.url + "/users", formData, {
            // _method: 'patch',
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            // submitButton.classList.remove(
            //     "spinner",
            //     "spinner-light",
            //     "spinner-right"
            //   );
            this.$bvToast.toast(
              "Data Anda berhasil dikirim. Mohon menunggu verifikasi data oleh BKPM dan Supervisor wilayah.",
              {
                title: `Success `,
                variant: `success`,
                solid: true,
              }
            );
            this.clearregister();
            // console.log(res);
            document.getElementById("kt_login_signup_cancel").click();
            // this.$router.push({ name: "dashboard" })
            // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
          })
          .catch((err) => {
            // submitButton.classList.remove(
            //     "spinner",
            //     "spinner-light",
            //     "spinner-right"
            //   );
            // console.log(err)
            this.$bvToast.toast(err.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return err;
          });
      }
    },
    lupapassword() {
      const email = this.email;
      axios
        .post(
          // "http://54.255.5.142:8000/forget-password",
          this.url + "/forget-password",
          {
            email,
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          this.$bvToast.toast("Berhasil Dikirim, Silahkan Cek Email", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // this.loaddata()
          return res;
        })
        .catch((err) => {
          // console.log(err)
          this.$bvToast.toast("Gagal Dikirim", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },
    clearregister() {
      this.name = "";
      this.username = "";
      this.email = "";
      this.id_ptsp = "";
      this.password = "";
      this.tanggal_lahir = "";
      this.tempat_lahir = "";
      this.ijazah = "";
      this.id_role = "";
      this.name_role = "";
      this.pendidikan_terakhir = "";
      this.no_hp = "";
      this.alamat = "";
      this.bidang_ilmu = "";
      this.$refs.file.value = null;
      this.$refs.file.text[0] = null;
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    visibility3() {
      var x = document.getElementById("login_password");
      if (x.type === "password") {
        x.type = "text";
        $("#eyeShow").show();
        $("#eyeSlash").hide();
      } else {
        x.type = "password";
        $("#eyeShow").hide();
        $("#eyeSlash").show();
      }
    },
  },
  created() {
    // this.getuser(this.user);
  },
};
</script>
